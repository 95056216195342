import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import ScrollToTop from './components/ScrollToTop';
import ProgressBar from './components/ProgressBar';
import Home from "./pages/Home";
import AboutUs from "./pages/AboutUs";
import OurPeople from "./pages/OurPeople";
import { PopupProvider } from "./providers/PopupProvider";

import "./App.scss";

function NotFound() {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/");
  }, [navigate]);
  return null;
}

function App() {
  return (
    <PopupProvider>
      <Router>
        <ProgressBar />
        <ScrollToTop />
        <div className="App">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about-us" element={<AboutUs />} />
            <Route path="/our-people" element={<OurPeople />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>
    </PopupProvider>
  );
}

export default App;
