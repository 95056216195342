import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NProgress from 'nprogress';
import 'nprogress/nprogress.css';

function ProgressBar() {
  const location = useLocation();

  useEffect(() => {
    // Start the progress bar
    NProgress.start();

    // Function to stop the progress bar
    const stopProgressBar = () => {
      NProgress.done();
    };

    // Stop the progress bar when the location changes
    stopProgressBar();

    // Stop the progress bar when the component unmounts
    return stopProgressBar;
  }, [location]);

  return null;
}

export default ProgressBar;
