import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowDownOutlined } from "@ant-design/icons";
import '@/styles/AppBanner.scss';

function AppBanner({ image, heading, description, isShowButton }) {
  const { t } = useTranslation();
  return (
    <div
      className="app-banner"
      style={{
        backgroundImage: `url(${process.env.PUBLIC_URL + image})`,
      }}
    >
      <div className="app-banner__content">
        <span className="banner__heading">{t(heading)}</span>
        <span className="banner__description">{t(description)}</span>
        {isShowButton && (
          <div className="banner__button">
            <Button type="primary" shape="circle" icon={<ArrowDownOutlined />} size='large' />
          </div>
        )}
      </div>
    </div>
  );
}
export default AppBanner;
