import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useContext } from "react";
import { PopupContext } from "@/providers/PopupProvider";

import "@/styles/Footer.scss";

function Footer() {
  const { t } = useTranslation();
  const { openPopup } = useContext(PopupContext);

  return (
    <>
      <div className="app-footer__main">
        <div className="vector">
          <div
            className="section-vector"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "images/vector_2.png"
              })`,
            }}
          />
        </div>
        <div className="left-side">
          <div className="title">{t("footer.heading")}</div>
          <div className="description">{t("footer.description")}</div>
          <div className="button">
            <Button type="primary" size="large">
              <span className="mr-2">{t("footer.button.emailAlert")}</span>
              <span>
                <ArrowRightOutlined />
              </span>
            </Button>
          </div>
        </div>
        <div className="right-side">
          <div className="contact">
            <div className="title">{t("footer.contact")}</div>
            <div className="address">
              <p>
                Holla Technology, 44 Nguyen Binh Khiem Street, Da Kao Ward,
                District 1, Ho Chi Minh, Vietnam, 70000
              </p>
              <p>
                <a href="tel:+840878768668" target="_blank" rel="noreferrer">
                  [+84] 087.87.68.668
                </a>
              </p>
              <p>
                <a
                  href="mailto:info@hollatech.co"
                  target="_blank"
                  rel="noreferrer"
                >
                  info@hollatech.co
                </a>
              </p>
            </div>
          </div>
          <div className="sitemap">
            <div className="title">{t("footer.sitemap")}</div>
            <div className="links">
              <Link to="/about-us">{t("footer.aboutUs")}</Link>
              <Link to="/our-people">{t("footer.ourPeople")}</Link>
              {/*<Link to="/">{t("footer.ourVision")}</Link>*/}
              <a href="javascript;;">
                <span
                  onClick={(e) => {
                    e.preventDefault();
                    openPopup();
                  }}
                >
                  {t("footer.contact")}
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="app-footer__copyright">
        {t("footer.copyRight")} {new Date().getFullYear()}
      </div>
    </>
  );
}

export default Footer;
