import { useContext } from "react";
import { useEffect } from "react";
import { PopupContext } from "@/providers/PopupProvider";
import ContactUsForm from "@/components/ContactUsForm";
import { CloseOutlined } from "@ant-design/icons";
import "@/styles/Popup.scss";

function Popup() {
  const { isPopupOpen, closePopup } = useContext(PopupContext);

  useEffect(() => {
    if (isPopupOpen) {
      document.body.classList.add("no-scroll");
    } else {
      document.body.classList.remove("no-scroll");
    }
  }, [isPopupOpen]);

  if (!isPopupOpen) {
    return null;
  }

  return (
    <div className="custom-popup">
      <div className="popup__content">
        <div className="close-button">
          <CloseOutlined onClick={closePopup} />
        </div>
        <ContactUsForm />
      </div>
    </div>
  );
}

export default Popup;
