import React, { useState, useEffect } from "react";
import AppHeader from "@/components/Header";
import AppFooter from "@/components/Footer";
import Popup from "@/components/Popup";
import "@/styles/Layout.scss";
const Layout = ({ children }) => {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const onScroll = () => {
      setScrolled(window.scrollY !== 0);
    };

    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="main-layout">
      <Popup />
      <div className={`app-header ${scrolled ? "scrolled" : ""}`}>
        <AppHeader />
      </div>
      <div className="app-content">
        <main>{children}</main>
      </div>
      <div className="app-footer">
        <AppFooter />
      </div>
    </div>
  );
};

export default Layout;
