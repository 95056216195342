import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Input } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import "@/styles/ContactUsForm.scss";

function ContactUsForm() {
  const { t } = useTranslation();
  return (
    <div className="contact-us-form">
      <div className="heading">
        <h1>{t("contactUsForm.heading")}</h1>
      </div>
      <Form
        variant="outlined"
        layout="vertical"
        labelCol={{
          span: 24,
        }}
      >
        <Form.Item
          label={t("contactUsForm.form.name")}
          name="name"
          rules={[
            {
              required: true,
              message: t("contactUsForm.formMessage.name"),
            },
          ]}
        >
          <Input
            style={{ borderRadius: 0 }}
            size="large"
            placeholder={t("contactUsForm.formPlaceholder.name")}
          />
        </Form.Item>

        <div className="md:grid md:grid-cols-2 gap-4 w-full">
          <Form.Item
            label={t("contactUsForm.form.email")}
            name="email"
            rules={[
              {
                required: true,
                message: t("contactUsForm.formMessage.email"),
              },
              {
                type: "email",
                message: t("contactUsForm.formMessage.emailValidation"),
              },
            ]}
          >
            <Input
              style={{ borderRadius: 0 }}
              size="large"
              placeholder={t("contactUsForm.formPlaceholder.email")}
            />
          </Form.Item>
          <Form.Item
            label={t("contactUsForm.form.phoneNumber")}
            name="phoneNumber"
            rules={[
              {
                required: true,
                message: t("contactUsForm.formMessage.phoneNumber"),
              },
              {
                validator: (_, value) => {
                  if (!value) {
                    return Promise.resolve();
                  }
                  const isValidPhone = /^(\+\d{1,3}[- ]?)?\d{10}$/.test(value);
                  return isValidPhone
                    ? Promise.resolve()
                    : Promise.reject(
                        new Error(
                          t("contactUsForm.formMessage.phoneNumberValidation")
                        )
                      );
                },
              },
            ]}
          >
            <Input
              style={{ borderRadius: 0 }}
              size="large"
              placeholder={t("contactUsForm.formPlaceholder.phoneNumber")}
            />
          </Form.Item>
        </div>

        <Form.Item
          label={t("contactUsForm.form.description")}
          name="description"
          rules={[
            {
              required: true,
              message: t("contactUsForm.formMessage.description"),
            },
          ]}
        >
          <Input.TextArea
            style={{ borderRadius: 0 }}
            placeholder={t("contactUsForm.formPlaceholder.description")}
          />
        </Form.Item>

        <Form.Item className="mt-2">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            className="flex items-center"
          >
            <span>{t("contactUsForm.form.button")}</span>
            <ArrowRightOutlined />
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
export default ContactUsForm;
