import React from "react";
import AppBanner from "@/components/AppBanner";
import Layout from "@/layouts";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import MeetTeam from "@/components/MeetTeam";

import "@/styles/AboutUs.scss";

function AboutUs() {
  const { t } = useTranslation();
  return (
    <Layout>
      <div className="about-us-page">
        <AppBanner
          image="images/about-us_banner.png"
          heading="aboutUs.banner.heading"
          description="aboutUs.banner.description"
        />
        <div className="about-us-page__content">
          <ScrollAnimation
            animateIn="fadeIn"
            animateOut="fadeOut"
            duration={1}
            animateOnce={true}
          >
            <div className="core-value">
              <div className="core-value__image">
                <img
                  src={process.env.PUBLIC_URL + "images/img_6.png"}
                  alt="core-value"
                />
              </div>
              <div className="core-value__content">
                <div className="title">{t("aboutUs.content.title")}</div>
                <div className="description">
                  <p>{t("aboutUs.content.description1")}</p>
                  <p>{t("aboutUs.content.description2")}</p>
                </div>
              </div>
            </div>
          </ScrollAnimation>

          <MeetTeam />
        </div>
      </div>
    </Layout>
  );
}
export default AboutUs;
