import React from "react";
import { useTranslation } from "react-i18next";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { ArrowRightOutlined } from "@ant-design/icons";
import '@/styles/MeetTeam.scss';

function MeetTeam() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routeToOurPeople = () => {
    navigate("/our-people");
  }

  return (
    <div className="meet-the-team-section">
      <div className="section-heading">
        <div className="title">{t("home.section.ourPeople.heading1")}</div>
        <div className="subtitle">{t("home.section.ourPeople.heading2")}</div>
        <div className="description">
          {t("home.section.ourPeople.description")}
        </div>
        <div className="button">
          <Button type="primary" size="large" onClick={routeToOurPeople}>
            <span className="mr-2">{t("home.section.ourPeople.button")}</span>
            <span>
              <ArrowRightOutlined />
            </span>
          </Button>
        </div>
      </div>
    </div>
  );
}
export default MeetTeam;
