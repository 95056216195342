import "@/styles/Header.scss";
import React, { useState, useEffect } from "react";
import { Menu } from "antd";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { PopupContext } from "@/providers/PopupProvider";
import { useContext } from "react";
import { Drawer } from "antd";
import { MenuOutlined } from "@ant-design/icons";

function Header() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openPopup } = useContext(PopupContext);

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const onResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", onResize);

    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);
  const items = [
    {
      label: t("header.nav.home"),
      key: "home",
    },
    {
      label: t("header.nav.aboutUs"),
      key: "about-us",
    },
    {
      label: t("header.nav.ourPeople"),
      key: "our-people",
    },
    //{
    //  label: t("header.nav.ourVision"),
    //  key: "our-vision",
    //},
    {
      label: t("header.nav.contact"),
      key: "contact",
    },
  ];

  const [current, setCurrent] = useState("home");
  const [drawerVisible, setDrawerVisible] = useState(false);

  const onClick = (e) => {
    if (e.key === "contact") {
      setDrawerVisible(false);
      openPopup();
      return;
    }
    setCurrent(e.key);
    navigate(`/${e.key}`);
  };

  useEffect(() => {
    const path = window.location.pathname.split("/")[1];
    setCurrent(path === "" ? "home" : path);
  }, []);

  return (
    <>
      <div
        className="app-header__logo"
        onClick={() => {
          setCurrent("home");
          navigate("/");
        }}
      >
        Holla Technology
      </div>
      <div className="app-header__nav">
        {isMobile ? (
          <>
            <MenuOutlined
              style={{ fontSize: "24px" }}
              onClick={() => setDrawerVisible(true)}
            />
            <Drawer
              title=""
              placement="right"
              closable={false}
              onClose={() => setDrawerVisible(false)}
              open={drawerVisible}
            >
              <Menu
                onClick={onClick}
                selectedKeys={[current]}
                mode="vertical"
                items={items}
              />
            </Drawer>
          </>
        ) : (
          <Menu
            onClick={onClick}
            selectedKeys={[current]}
            mode="horizontal"
            items={items}
          />
        )}
      </div>
    </>
  );
}
export default Header;
