import React from "react";
import Layout from "@/layouts";
import "@/styles/Home.scss";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button } from "antd";
import { ArrowRightOutlined } from "@ant-design/icons";
import ScrollAnimation from "react-animate-on-scroll";
import AppBanner from "@/components/AppBanner";

import "swiper/css";
import "swiper/css/pagination";

import { Autoplay, Pagination, Mousewheel, Keyboard } from "swiper/modules";
import MeetTeam from '../components/MeetTeam';

function Home() {
  const { t } = useTranslation();
  const sliderImages = [
    "images/slide_1.png",
    "images/slide_2.png",
    "images/slide_3.png",
    "images/slide_4.png",
  ];
  const coreValues = [
    {
      title: t("home.section.coreValues.title1"),
      description: t("home.section.coreValues.description1"),
      image: "images/img_1.png",
    },
    {
      title: t("home.section.coreValues.title2"),
      description: t("home.section.coreValues.description2"),
      image: "images/img_2.png",
    },
    {
      title: t("home.section.coreValues.title3"),
      description: t("home.section.coreValues.description3"),
      image: "images/img_3.png",
    },
    {
      title: t("home.section.coreValues.title4"),
      description: t("home.section.coreValues.description4"),
      image: "images/img_4.png",
    },
    {
      title: t("home.section.coreValues.title5"),
      description: t("home.section.coreValues.description5"),
      image: "images/img_5.png",
    },
  ];

  const routeToHolloSpeak = () => {
    try {
      window.open("https://holospeak.vn/en/home/", "_blank");
    } catch (error) {
      console.log("error", error);
    }
  }

  return (
    <Layout>
      <div className="home-page">
        <AppBanner isShowButton={true} image="images/home-banner.png" heading="home.banner.heading" description="home.banner.description" />
        <div className="vector vector-1">
          <div
            className="section-vector"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "images/vector.png"
              })`,
            }}
          />
        </div>
        <div className="home-page__section section-1">
          <div className="section-heading">
            {t("home.section.ourProduct.heading")}
          </div>
          <div className="section-content">
            <div className="left-side">
              <div className="holospeak-logo">
                <img
                  src={process.env.PUBLIC_URL + "images/holospeak_logo.png"}
                  alt="holospeak-logo"
                />
              </div>
              <div className="title">{t("home.section.ourProduct.title")}</div>
              <div className="description">
                {t("home.section.ourProduct.description")}
              </div>
              <div className="button">
                <Button type="primary" size="large" onClick={routeToHolloSpeak}>
                  <span className="mr-2">
                    {t("home.section.ourProduct.button")}
                  </span>
                  <span>
                    <ArrowRightOutlined />
                  </span>
                </Button>
              </div>
            </div>
            <div className="right-side">
              <Swiper
                cssMode={true}
                navigation={true}
                pagination={true}
                mousewheel={true}
                keyboard={true}
                modules={[Autoplay, Pagination, Mousewheel, Keyboard]}
                spaceBetween={50}
                slidesPerView={1}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                onSlideChange={() => console.log("slide change")}
                onSwiper={(swiper) => console.log(swiper)}
              >
                {sliderImages.map((image, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <img
                        src={process.env.PUBLIC_URL + image}
                        alt={`slide-${index}`}
                      />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          </div>
        </div>
        <div className="home-page__section section-2">
          <div className="section-heading">
            <div className="title">{t("home.section.coreValues.heading")}</div>
            <div className="description">
              <span>{t("home.section.coreValues.description")}</span>
            </div>
          </div>
          <div className="section-content">
            {coreValues.map((value, index) => {
              return (
                <ScrollAnimation key={index} animateIn="fadeIn" animateOut='fadeOut' duration={1} animateOnce={true}>
                  <div
                    key={index}
                    className={`core-value ${index % 2 === 0 ? "even" : "odd"}`}
                  >
                    <div className="core-value__image">
                      <img
                        src={process.env.PUBLIC_URL + value.image}
                        alt={`core-value-${index}`}
                      />
                    </div>
                    <div className="core-value__content">
                      <div className="title">{value.title}</div>
                      <div className="description">{value.description}</div>
                    </div>
                  </div>
                </ScrollAnimation>
              );
            })}
          </div>
        </div>
        <div className="home-page__section section-3">
          <MeetTeam />
        </div>
        <div className="vector vector-2">
          <div
            className="section-vector"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "images/vector.png"
              })`,
            }}
          />
        </div>
      </div>
    </Layout>
  );
}

export default Home;
